<template>
  <v-row no-gutters class="fill-height py-5 px-2">
    <v-col cols="6">
      <v-data-table @click:row="getTimeline" fixed-header :items-per-page="-1" hide-default-footer item-key="id"
        single-select sort-by="userSessionDate" :sort-desc="true" :height="kioskAnalyticsDimension.userSessionheight"
        :headers="userSessionHeader" :items="userSessionList" loading-text="Loading... Please wait" :loading="loading">
        <template v-slot:item.count="{ item }">
          {{ item.id }}
        </template>
        <template v-slot:item.userSessionDate="{ item }">
          {{ item.userSessionDate | formatDate }}
        </template>
        <template v-slot:item.userSessionDuration="{ item }">
          {{ item.userSessionDuration | formatDuration }}
        </template>
        <template v-slot:item.userSessionStartTime="{ item }">
          {{ item.userSessionStartTime | formatTime }}
        </template>
        <template v-slot:item.userSessionEndTime="{ item }">
          {{ item.userSessionEndTime | formatTime }}
        </template>
      </v-data-table>
    </v-col>
    <v-col cols="6" align="center">
      <h5 class="text-h5 primary--text">Session Timeline</h5>
      <span v-if="timeline === null">Select a session to view the timeline.</span>
      <v-card class="pr-3 my-3 overflow-y-auto" v-else :height="kioskAnalyticsDimension.userSessionheight - 75" flat>
        <span v-if="timeline.length === 0">
          User detected but no interaction took place.
        </span>
        <v-timeline v-else dense clipped class="my-1">
          <!-- <v-virtual-scroll
            :height="viewPortHeight"
            item-height="120"
            bench="0"
            :items="timeline"
          >
            <template v-slot:default="{ item }"> -->
          <div v-for="(item, i) in timeline" :key="i">
            <v-timeline-item v-if="item.actionType.toLowerCase() === 'session started'" :key="Math.random()"
              color="success" icon='mdi-login-variant'  fill-dot>
              <v-row no-gutters>
                <v-col class="text-left" cols="9">
                  <!-- <span class="caption">Module: </span> -->
                  <div class="font-weight-medium pb-5 mt-3">Session Started</div>
                  <!-- <br />
                  <span class="caption">Action: </span>
                  <span class="caption font-weight-medium">{{
                    item.action
                  }}</span>
                  <br /> -->
                  <!-- <span class="caption">Response: </span>
                  <span class="caption font-weight-medium">{{
                    item.response | trimLength
                  }}</span> -->
                </v-col>
                <v-col class="text-right pb-5 mt-3">
                  {{ item.timeStamp | formatStringTime }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-timeline-item>
            <v-timeline-item v-if="item.actionType.toLowerCase() === 'end session'" :key="Math.random()" color="error"
              icon='mdi-logout-variant' class="mb-4" fill-dot>
              <v-row no-gutters>
                <v-col class="text-left" cols="9">
                  <div class="font-weight-medium pb-5 mt-3">Session Ended</div>
                </v-col> 
                <v-col class="text-right">
                  {{ item.timeStamp | formatStringTime }}
                </v-col>
              </v-row>
            </v-timeline-item>
            <v-timeline-item v-else-if="item.actionType.toLowerCase() === 'speech'" :key="Math.random()"
              color="orange lighten-2" icon="mdi-bullhorn-outline" class="mb-4" fill-dot>
              <v-row no-gutters>
                <v-col class="text-left" cols="9">
                  <span class="caption">Question: </span>
                  <span class="caption font-weight-medium">{{
                    item.transcript.trim()
                  }}</span>
                  <br />
                  <span class="caption">Response: </span>
                  <span class="caption font-weight-medium">{{
                    item.response.answers[0].answer | trimLength
                  }}</span>
                </v-col>
                <v-col class="text-right">
                  {{ item.timeStamp | formatStringTime }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-timeline-item>
            <v-timeline-item v-else-if="item.actionType.toLowerCase() === 'touch'" :key="Math.random()"
              color="primary" icon="mdi-gesture-tap" class="mb-4" fill-dot>
              <v-row no-gutters>
                <v-col class="text-left" cols="9">
                  <span class="caption">Module: </span>
                  <span class="caption font-weight-medium">{{
                    item.module
                  }}</span>
                  <br />
                  <span class="caption">Action: </span>
                  <span class="caption font-weight-medium">{{
                    item.action
                  }}</span>
                  <br>
                  <span class="caption">Response: </span>
                  <span class="caption font-weight-medium">{{
                    item.response | trimLength
                  }}</span>
                </v-col>
                <v-col class="text-right">
                  {{ item.timeStamp | formatStringTime }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-timeline-item>
            <v-timeline-item v-else-if="item.actionType.toLowerCase() === 'email'" :key="Math.random()"
              color="blue-grey lighten-2" icon="mdi-email-outline" class="mb-4" fill-dot>
              <v-row no-gutters>
                <v-col class="text-left" cols="9">
                  <span class="caption">Module: </span>
                  <span class="caption font-weight-medium">{{
                    item.module
                  }}</span>
                  <br />
                  <span class="caption">Action: </span>
                  <span class="caption font-weight-medium">{{
                    item.action
                  }}</span>
                  <br />
                  <span class="caption">Attachemnt: </span>
                  <span class="caption font-weight-medium">{{
                    item.response | trimLength
                  }}</span>
                </v-col>
                <v-col class="text-right">
                  {{ item.timeStamp | formatStringTime }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-timeline-item>
            <!-- <v-timeline-item v-else :key="Math.random()" icon="mdi-gesture-tap" class="mb-4" fill-dot>
              <v-row no-gutters>
                <v-col class="text-left" cols="9">
                  <span class="caption">Module: </span>
                  <span class="caption font-weight-medium">{{
                    item.module
                  }}</span>
                  <br />
                  <span class="caption">Action: </span>
                  <span class="caption font-weight-medium">{{
                    item.action
                  }}</span>
                  <br />
                  <span class="caption">Response: </span>
                  <span class="caption font-weight-medium">{{
                    item.response | trimLength
                  }}</span>
                </v-col>
                <v-col class="text-right">
                  {{ item.timeStamp | formatStringTime }}
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-timeline-item> -->
          </div>
          <!-- </template>
          </v-virtual-scroll> -->
        </v-timeline>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "userSession",
  props: {
    userSessionList: Array,
    loading: Boolean,
  },
  data() {
    return {
      timeline: null,
      selectedSessionId: null,
      userSessionHeader: [
        { text: "Session ID", align: "id", value: "count" },
        { text: "Date", value: "userSessionDate" },
        { text: "Start Time", value: "userSessionStartTime" },
        { text: "End Time", value: "userSessionEndTime" },
        { text: "Duration", value: "userSessionDuration" },
      ],
      viewPortHeight: window.innerHeight / 2,
      sessionTimelineHeight: window.innerHeight / 2.25,
    };
  },
  computed: {
    ...mapGetters(["kioskAnalyticsDimension"]),
    // viewPortHeight() {
    //   return window.innerHeight;
    // },
  },
  methods: {
    getTimeline(item, row) {
      console.log('At view timeline: ', item)
      this.timeline = item.userSessionLogs;
      row.select(true);
      this.selectedSessionId = item.id;
    },
  },
  watch: {
  },
  filters: {
    formatStringTime(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("LTS");
    },
    trimLength(val) {
      if (val === null || val === "") return val;
      if (val.length <= 50) {
        return val;
      }
      return `${val.substring(0, 80)}...`;
    },
    formatTime(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("LT");
    },
    formatDate(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("L");
    },
    formatDuration(val) {
      if (!val) {
        return "-";
      }
      return moment(val).format("mm:ss");
    },
  },
};
</script>

<style>
tr.v-data-table__selected {
  background: #1976d2 !important;
  color: #ffff !important;
}
</style>